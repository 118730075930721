import bouquet from './images/bouquet.jpg';
import boutonnieres from './images/boutonnieres.jpg';
import bridemaids from './images/bridemaids.jpg';
import bridewithbouqetkissinggroom from './images/bridewithbouqetkissinggroom.jpg';
import bridewithbouquet from './images/bridewithbouquet.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
     
        <p>
          Welcome to Kathryn Simon Studios
        </p><p>
          <img src={bouquet} className="Picture" alt="bouquet" />
        </p><p>
          <img src={boutonnieres} className="Picture" alt="boutonnieres" />
        </p><p>
          <img src={bridemaids} className="Picture" alt="bridemaids" />
        </p><p>
          <img src={bridewithbouqetkissinggroom} className="Picture" alt="bride with bouquet kissing groom" />
        </p><p>
          <img src={bridewithbouquet} className='Picture' alt='bride with bouquet' />
        </p>
          


      </header>
    </div>
  );
}

export default App;
